import React, { useEffect } from 'react';
import { H2 } from '@ph-bit/design.ui.typography.h2';
import { H4 } from '@ph-bit/design.ui.typography.h4';
import { P2 } from '@ph-bit/design.ui.typography.p2';
import { Link } from '@ph-bit/design.ui.buttons.link';
import {
  Pill,
  PillColorTheme,
  PillVariant
} from '@ph-bit/design.ui.buttons.pill';
import {
  BasicFields,
  ButtonLabel,
  CalendlyForms,
  ContactPageLayout,
  styles,
  useContactForm,
  YesNo
} from '../../redesign/molecules/contact';
import { ControllerDropdown } from '../../redesign/atoms/controller-dropdown/controller-dropdown';
import { capitalizeFirstLetter } from '../../lib/helpers';

const formatOption = (o: string) =>
  ({
    text: capitalizeFirstLetter(o),
    value: o.toLowerCase()
  } as HTMLOptionElement);

const howDidYouHearAboutUsOptions = [
  'My employer',
  'My insurance',
  'Commune',
  'Web search',
  'Referral',
  'Social Media',
  'Melissa Wood Health',
  'Podcast',
  'Other'
];

const FreeConsultationPage: React.FC = () => {
  const {
    onSubmit,
    register,
    control,
    errors,
    onDropdownChange,
    isSelectedStateDisabled
  } = useContactForm({
    defaultValues: {
      coveredByEmployer: YesNo.NO,
      coveredByInsurance: YesNo.NO
    },
    form: CalendlyForms.SALES
  });

  useEffect(() => {
    window?.ours('track', 'ViewContent');
  }, []);

  return (
    <ContactPageLayout pageTitle="Contact Us | Parsley Health">
      <H2>Considering membership?</H2>
      <div className="my-8">
        <P2 className="mb-6 px-4">
          Learn everything you need to know about joining in a free 15-minute
          consultation with a Parsley advisor. Book a call below.
        </P2>
        <P2 className="mb-6 px-4">
          Already a member? please login to{' '}
          <Link href="https://my.parsleyhealth.com/">MyParsley</Link> to message
          your care team or{' '}
          <Link href="/call/member-check-in/">click here</Link> to schedule with
          customer service.
        </P2>
      </div>
      <H4>Enter your information</H4>
      <div className="my-6 w-full">
        <form className={styles.form} id="form" onSubmit={onSubmit}>
          <BasicFields
            control={control}
            errors={errors}
            isSelectedStateDisabled={isSelectedStateDisabled}
            onDropdownChange={onDropdownChange}
            register={register}
          />
          <ControllerDropdown
            name="howdidyouhearaboutus"
            control={control}
            options={howDidYouHearAboutUsOptions.map(o => formatOption(o))}
            label="How did you hear about us?"
            placeholder="Select One"
          />
          <div className="mt-6 inline-block">
            <Pill
              variant={PillVariant.primary}
              colorTheme={PillColorTheme.sage}
              type="submit"
              form="form"
            >
              {isSelectedStateDisabled
                ? ButtonLabel.Notify
                : ButtonLabel.Submit}
            </Pill>
          </div>
        </form>
      </div>
      <div className="flex-center mt-6">
        <P2 className="mb-6 px-4">
          By signing up, I agree to receive periodic communication from Parsley
          Health which I can opt out of at any time{' '}
          <Link
            href="https://www.parsleyhealth.com/electronic-communications-consent/"
            target="_blank"
          >
            (Electronic Communications Consent)
          </Link>
          . I have read and agree to the{' '}
          <Link
            href="https://www.parsleyhealth.com/terms-of-use/"
            target="_blank"
          >
            Terms of Use
          </Link>{' '}
          and{' '}
          <Link href="https://www.parsleyhealth.com/privacy/" target="_blank">
            Privacy Policy
          </Link>
          .
        </P2>
      </div>
    </ContactPageLayout>
  );
};

export default FreeConsultationPage;
